import React from 'react';
import { graphql, PageProps } from 'gatsby';
import tw from 'twin.macro';
import css from '@emotion/css';
import BaseLayout from '../layouts/base';
import Img, { FluidObject } from 'gatsby-image';
import { Helmet } from 'react-helmet';
import { GetRequisiteQuery } from '../graphql-types';

import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const H2 = tw.h2`font-gilroy text-h4 text-black-100 dark:text-white mb-2 lg:mb-4`;
const commonOptions = {
  renderNode: {
    [BLOCKS.HEADING_2]: (_, children) => <H2>{children}</H2>,
    [INLINES.HYPERLINK]: (node, children) => {
      const url = node.data.uri;
      return (
        <a
          tw="underline text-black-100 dark:text-white dark:visited:text-white"
          href={url}
          target="__blank"
        >
          {children}
        </a>
      );
    },
  },
};

const Breadcrumbs: React.FC<{
  style?: React.CSSProperties;
  className?: string;
  data: string[];
}> = ({ style, className, data }) => (
  <div style={style} className={className} tw="flex dark:text-white text-b2">
    {data.join(' > ')}
  </div>
);

class Requisite extends React.Component<PageProps<GetRequisiteQuery>> {
  constructor(props: PageProps<GetRequisiteQuery>) {
    super(props);
  }

  render() {
    const data = this.props.data;
    const pageData = data?.allContentfulSyaratPenggunaanLayanan?.nodes[0];
    const logo = data?.logo?.childrenImageSharp[0]?.fluid;

    // SEO data
    const siteMeta = data?.site?.siteMetadata;
    const canonical = `${siteMeta?.url}/`;
    const seo = pageData?.seo;

    const breadcrumbs = ['Beranda', pageData?.title];

    const jsonLd = {
      '@context': 'https://schema.org',
      '@graph': [
        {
          '@type': 'BreadcrumbList',
          itemListElement: [
            ...breadcrumbs.map((b, i) => {
              return {
                '@type': 'ListItem',
                position: i + 1,
                name: b,
                item: i === 0 ? `${siteMeta.url}/` : `${siteMeta.url}/${pageData?.uid}/`,
              };
            }),
          ],
        },
        {
          '@type': 'NewsArticle',
          author: [
            {
              '@type': 'Person',
              name: seo?.author,
            },
          ],
          datePublished: seo?.updatedAt || '2021-03-22T05:34:36+00:00',
          headline: seo?.title || `${pageData?.title} - Easybiz`,
          // image: imageUrl,
          publisher: {
            '@type': 'Organization',
            name: 'Easybiz',
            logo: {
              '@type': 'ImageObject',
              url: siteMeta?.logo,
            },
          },
        },
      ],
    };

    return (
      <BaseLayout logo={logo} fixedHeader={true}>
        <Helmet>
          <title>{seo?.title || 'Requisites - Easybiz'}</title>
          <meta
            name="description"
            content={
              seo?.description?.description ||
              'Jasa Pendirian PT, Badan Usaha, dan Perizinan di Jakarta'
            }
          />
          <meta name="keywords" content={seo?.keyword} />
          <meta
            name="robots"
            content={
              seo?.robots ||
              'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
            }
          />
          <link rel="canonical" href={canonical} />
          <meta name="author" content={seo?.author} />
          <meta name="publisher" content={seo?.publisher} />
          <meta property="og:locale" content={seo?.oglocale || 'en_US'} />
          <meta property="og:type" content={seo?.ogtype || 'website'} />
          <meta
            property="og:title"
            content={seo?.title || 'Jasa Pendirian PT, Badan Usaha, dan Perizinan di Jakarta.'}
          />
          <meta
            property="og:description"
            content={
              seo?.description?.description ||
              'Jasa Pendirian PT, Badan Usaha, dan Perizinan di Jakarta'
            }
          />
          <meta property="og:url" content={canonical} />
          {/* <meta property="og:image" content={pageData?.jumbotron?.file?.url} /> */}
          <meta property="og:site_name" content="Easybiz" />
          <meta
            name="twitter:title"
            content={seo?.title || 'Jasa Pendirian PT, Badan Usaha, dan Perizinan di Jakarta.'}
          />
          <meta
            name="twitter:description"
            content={
              seo?.description?.description ||
              'Jasa Pendirian PT, Badan Usaha, dan Perizinan di Jakarta'
            }
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content={canonical} />
          {/* <meta name="twitter:image" content={pageData?.jumbotron?.file?.url} /> */}
          <meta name="twitter:creator" content={seo?.author} />
          <script type="application/ld+json">{JSON.stringify(jsonLd, undefined, 4)}</script>
        </Helmet>
        <section tw="bg-white dark:bg-black-200">
          <div tw="container">
            <div tw="mx-0 lg:mx-52 pt-28 pb-0 grid grid-cols-1 lg:grid-cols-3 lg:gap-4">
              <div tw="lg:col-span-2">
                <Breadcrumbs tw="mx-4 lg:mx-0" data={breadcrumbs}></Breadcrumbs>
                <div tw="my-4 mx-4 lg:mx-0">
                  {pageData.image?.fluid ? (
                    <Img
                      alt={pageData?.title || pageData?.uid}
                      tw="flex-none mr-4 rounded-lg mb-6"
                      fluid={pageData.image?.fluid as FluidObject}
                      css={css`
                        height: 330px;
                        width: 100%;
                        @media (max-width: 1024px) {
                          height: 200px;
                          width: 100%;
                        }
                      `}
                    />
                  ) : (
                    <div
                      tw="flex-none mr-4 rounded-lg mb-6 bg-black-500 dark:bg-black-300"
                      css={css`
                        height: 330px;
                        width: 100%;
                        @media (max-width: 1024px) {
                          height: 200px;
                          width: 100%;
                        }
                      `}
                    ></div>
                  )}
                  <div tw="mt-4">
                    <div tw="mt-4">
                      <h1 tw="text-h3 dark:text-white mb-4 font-extrabold leading-8">
                        {pageData.title}
                      </h1>
                    </div>
                    <div
                      tw="mx-0 text-black-100 text-b1 dark:text-white font-ibm"
                      css={css`
                        letter-spacing: 0.02em;
                      `}
                    >
                      {documentToReactComponents(JSON.parse(pageData?.content?.raw), commonOptions)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </BaseLayout>
    );
  }
}

export default Requisite;

export const query = graphql`
  query getRequisite {
    allContentfulSyaratPenggunaanLayanan {
      nodes {
        uid
        seo {
          title
          description {
            description
          }
          keyword
          language
          robots
          author
          publisher
          updatedAt
          oglocale
          ogtype
        }
        title
        content {
          raw
        }
        image {
          file {
            url
            fileName
          }
          fluid(maxHeight: 1000, maxWidth: 1000) {
            ...ContentfulImageFluidFields
          }
        }
      }
    }
    logo: file(relativePath: { eq: "images/logo.png" }) {
      childrenImageSharp {
        fluid(maxWidth: 130) {
          ...FileImageSharpFluid
        }
      }
    }
    jumbotron: file(relativePath: { eq: "images/jumbotron/home.jpg" }) {
      childrenImageSharp {
        fluid(maxWidth: 1440) {
          ...FileImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        ...SiteMetadataFields
      }
    }
  }
`;
